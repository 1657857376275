export default {
  // 登录
  login: 10001,
  // 获取用户信息
  getUserInfo: 10002,
  // 修改密码
  updatePassword: 10003,
  // 项目列表
  getProList: 31001,
  // 项目详情
  getProDetail: 31002,
  // 提交飞行检查
  submitCheck: 32001,
  // 获取门店类别
  getShopList: 90001,
  // 文件上传
  uploadFile: 90002
}
