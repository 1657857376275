import axios from 'axios'
// import { Message } from 'view-design'
import { Toast } from 'mint-ui'
import router from '@/router'
import store from '@/store'
import { randomString } from '@/utils'
import MD5 from '@/utils/md5'
const KEY = 'LebEz8nTG1Zi' // *(加密密钥)

const http = axios.create({
  timeout: 60000
})

http.$post = (method, params, headers = {}) => {
  const device_id = randomString()
  const data = {
    ver: '1.0',
    os: 'web',
    device_id: device_id,
    timestamp: new Date().getTime(),
    token: store.getters.token,
    method,
    params
  }
  return http.post(
    process.env.VUE_APP_BASE_API_V2,
    data,
    {
      headers: {
        Auth: MD5.md5(method + device_id + KEY),
        ...headers
      }
    }
  )
}

http.interceptors.request.use(config => {
  // showLoading();
  return config
}, (error) => Promise.reject(error))

http.interceptors.response.use(response => {
  // closeLoading();
  const res = response.data
  if (res.code === 0) {
    return res
  } else {
    if (res.code === 1005 && res.message === '暂无数据') {
      console.log(res)
    } if (res.code === '104' || res.code === '103') {
      // token失效 缺少token
      store.dispatch('companyInfo/logout').then(res => {
        router.push({ name: 'login' })
      })
    } else {
      Toast({
        message: res.message
      })
    }
    return Promise.reject(res)
  }
}, (error) => {
  // closeLoading();
  if (error.name == 'Error') {
    Toast({
      message: error.msg
    })
  } else {
    Toast({
      message: error.response.data.data || error.message
    })
  }

  return Promise.reject(error)
})

export default http
