import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/newLogin')
  },
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/index'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home'),
        meta: {
          title: '工单列表'
        }
      },
      {
        path: '/knowledge',
        name: 'knowledge',
        component: () => import('@/views/knowledge/index'),
        meta: {
          title: '知识库'
        }
      },
      {
        path: '/personal',
        name: 'personal',
        component: () => import('@/views/mine/personal'),
        meta: {
          title: '个人中心'
        }
      }
    ]
  },
  {
    path: '/knowledge/detail/:code',
    name: 'knowledgeDetail',
    component: () => import('@/views/knowledge/detail'),
    meta: {
      title: '知识库详情'
    }
  },
  // {
  //   path: '/knowledge/folderDetail/:code',
  //   name: 'folderDetail',
  //   component: () => import('@/views/knowledge/folderDetail'),
  //   meta: {
  //     title: '知识库详情'
  //   }
  // },
  // {
  //   path: '/knowledge/fileDetail',
  //   name: 'fileDetail',
  //   component: () => import('@/views/knowledge/fileDetail'),
  //   meta: {
  //     title: '知识库详情'
  //   }
  // },
  // 工单详情
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail'),
    meta: {
      title: '工单详情'
    }
  },
  // 返修工单详情
  {
    path: '/reworkingDetail',
    name: 'reworkingDetail',
    component: () => import('@/views/reworkingDetail'),
    meta: {
      title: '返修工单'
    }
  },
  // 诊断信息
  {
    path: '/diagnose',
    name: 'diagnose',
    component: () => import('@/views/diagnose'),
    meta: {
      title: '诊断信息'
    }
  },
  // 鉴定信息
  {
    path: '/authenticate',
    name: 'authenticate',
    component: () => import('@/views/authenticate'),
    meta: {
      title: '鉴定信息'
    }
  },
  // 修改密码
  {
    path: '/changeWord',
    name: 'changeWord',
    component: () => import('@/views/mine/changeWord'),
    meta: {
      title: '鉴定信息'
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition // 按下 后退/前进 按钮时，类似浏览器的原生表现
    } else {
      return { x: 0, y: 0 } // 让页面滚动到顶部
    }
  }
})

export default router
