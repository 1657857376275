
import port from '@/api/port'
import storge from '@/utils/storge'
const state = {
  categoryObj: null,
  historyList: storge.getItem('historyList') ? JSON.parse(storge.getItem('historyList')) : []
}

const mutations = {
  SET_CATEGORY_OBJ(state, categoryObj) {
    state.categoryObj = categoryObj
  },
  SET_HISTORY_LIST(state, historyList) {
    state.historyList = historyList
    storge.setItem('historyList', JSON.stringify(historyList))
  },
  UPDATE_HISTORY_LIST(state, historyItem) {
    const index = state.historyList.findIndex((item) => {
      return item === historyItem
    })
    if (index > -1) {
      state.historyList.splice(index, 1)
    }
    state.historyList.unshift(historyItem)
    storge.setItem('historyList', JSON.stringify(state.historyList))
  }
}

const actions = {
  getKnowledgeCategoryObj({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (!state.categoryObj) {
        port.getKnowledgeCategoryObj().then(res => {
          commit('SET_CATEGORY_OBJ', res.data)
          resolve()
        }).catch(() => {
          reject()
        })
      } else {
        resolve()
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
