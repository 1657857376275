const STORAGE_VERSION = '1.0.0'

const Storage = {
  setItem: (key, data) => {
    localStorage.setItem(key + '_' + STORAGE_VERSION, data)
  },
  getItem: (key, data) => {
    return localStorage.getItem(key + '_' + STORAGE_VERSION)
  },
  removeItem: (key) => {
    localStorage.removeItem(key + '_' + STORAGE_VERSION)
  }
}

export default Storage
