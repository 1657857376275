import { origin, originV2, originUpload } from './config'
import cookies from '@/utils/cookie.js'
export default {
  portAddress(params) { // 平台接口
    return origin(`/default/index`, params)
  },
  getDict(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '300010'
    })
  },
  upload(data) {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('type', data.type)
    formData.append('token', cookies.get('token'))
    return originUpload(formData)
  },
  getWorkDetail(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '200001'
    })
  },
  getDiagnoseList(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '200020'
    })
  },
  submitDiagnose(data, method) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method
    })
  },
  getKnowledgeCategoryObj() {
    return originV2({
      token: cookies.get('token'),
      method: '300001'
    })
  },
  getKnowledgeFolder(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '300002'
    })
  },
  getKnowledgeFile(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '300003'
    })
  }
}
