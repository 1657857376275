import Vue from 'vue'

import {
  InfiniteScroll,
  Popup,
  Spinner,
  Toast,
  Indicator,
  Swipe,
  SwipeItem,
  Tabbar,
  TabItem,
  IndexList,
  IndexSection,
  Cell
} from 'mint-ui'
import 'mint-ui/lib/style.css'

const components = [
  Swipe,
  SwipeItem,
  Tabbar,
  TabItem,
  Popup,
  IndexList,
  IndexSection,
  Cell
]

components.forEach((component) => {
  Vue.component(component.name, component)
})
Vue.use(InfiniteScroll)
Vue.prototype.$toast = Toast
Vue.prototype.$Indicator = Indicator



