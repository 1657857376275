<template>
  <div
    v-show="changeShow"
    class="modal-show"
    @touchmove.prevent
    @scroll.prevent
  >
    <div>
      <div class="top">
        <img src="../../assets/images/login/freeze.png" alt>您的账户已被冻结？
      </div>
      <div class="btn">
        <span @click="cancel">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtmModal',
  props: {
    value: Boolean,
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      changeShow: this.value
    }
  },
  watch: {
    value(val) {
      this.changeShow = val
    }
  },
  methods: {
    cancel() {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
      this.$emit('on-cancel')
    }
  }
}
</script>

<style scoped lang="less">
.modal-show {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 100%;
  z-index: 10;
  & > div {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 0.1rem 0.1rem 0 0;
    .top {
      height: 1.18rem;
      padding: 0.27rem;
      box-sizing: border-box;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
      img {
        width: 0.63rem;
        vertical-align: middle;
        margin-right: 0.15rem;
        position: relative;
        top: -0.05rem;
      }
    }
    & > .btn {
      height: 0.8rem;
      line-height: 0.8rem;
      width: 100%;
      span {
        float: left;
        display: inline-block;
        text-align: center;
        width: 100%;
        background-color: #2f8af1;
        border-top: 0.02rem solid #2f8af1;
        color: #fff;
        font-size: 0.24rem;
      }
    }
  }
}
</style>
